export enum Month {
    January = 'JANUARY',
    February = 'FEBRUARY',
    March = 'MARCH',
    April = 'APRIL',
    May = 'MAY',
    June = 'JUNE',
    July = 'JULY',
    August = 'AUGUST',
    September = 'SEPTEMBER',
    October = 'OCTOBER',
    November = 'NOVEMBER',
    December = 'DECEMBER'
}

export type MonthType = Month;
