import { useRouter } from 'next/router';

import { LogoIcon } from '@/components/Icons';
import { LogoMobileIconProps } from '@/components/Icons/LogoMobileIcon';

import NavLink from './NavLink';

export const BrandMobileLink = (props: LogoMobileIconProps) => {
    const router = useRouter();
    const { locale } = router;
    const href = props.href || '';

    return (
        <NavLink
            href={href}
            skipRouter={true}
            locale={locale}
            display="flex"
            justifyContent="center"
            position="relative"
            top="3px"
        >
            <LogoIcon h="auto" pointerEvents="none" maxW="100%" {...props} />
        </NavLink>
    );
};
