import { Select, SelectProps } from '@chakra-ui/react';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Month, MonthType } from '@/models/MonthType';

export type EmptyString = '';

type MonthDropdownProps = {
    monthSelect?: (month: MonthType | string | EmptyString) => void;
    selectProps?: SelectProps;
    lastTwelveMonths?: boolean;
};

export const MonthDropdown = (props: MonthDropdownProps) => {
    const { monthSelect, selectProps } = props;

    const months = useMemo(
        () => [
            { label: 'month.january', value: Month.January },
            { label: 'month.february', value: Month.February },
            { label: 'month.march', value: Month.March },
            { label: 'month.april', value: Month.April },
            { label: 'month.may', value: Month.May },
            { label: 'month.june', value: Month.June },
            { label: 'month.july', value: Month.July },
            { label: 'month.august', value: Month.August },
            { label: 'month.september', value: Month.September },
            { label: 'month.october', value: Month.October },
            { label: 'month.november', value: Month.November },
            { label: 'month.december', value: Month.December }
        ],
        []
    );

    const getOptions = () => {
        if (props.lastTwelveMonths) {
            const options = [];
            for (let i = 0; i < 13; i++) {
                const date = new Date();
                date.setMonth(date.getMonth() - 12 + i);
                options.push({
                    label: months[date.getMonth()].label,
                    month: date.getMonth() + 1,
                    year: date.getFullYear()
                });
            }
            return options.map((item, index) => {
                const { month, year, label } = item;
                const optionValue = `${month}/${year}`;
                return (
                    <option key={index} value={optionValue}>
                        <FormattedMessage id={label} /> {year}
                    </option>
                );
            });
        } else {
            return months.map((month) => (
                <option key={month.value} value={month.value}>
                    <FormattedMessage id={month.label} />
                </option>
            ));
        }
    };

    const options = getOptions();

    const onMonthChange = useCallback(
        (event: SyntheticEvent) => {
            const selectElement = event.target as HTMLSelectElement;
            const month = selectElement.value as MonthType | EmptyString;
            monthSelect?.(month);
        },
        [monthSelect]
    );

    return (
        <Select {...selectProps} onChange={onMonthChange}>
            <option value="">
                <FormattedMessage id="select-month" />
            </option>
            {options}
        </Select>
    );
};
