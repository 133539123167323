import { TableCellProps, TableRowProps, Td, Tr } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type LevelsHistorySpanCellProps = {
    columns: number;
    rowStyles?: TableRowProps;
    cellStyles?: TableCellProps;
};

export function TableRowSpanCell(props: PropsWithChildren<LevelsHistorySpanCellProps>) {
    const { columns, children, cellStyles, rowStyles } = props;

    return (
        <Tr {...rowStyles}>
            <Td colSpan={columns} {...cellStyles}>
                {children}
            </Td>
        </Tr>
    );
}
