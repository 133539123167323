import { ComponentStyleConfig, defineStyle, StyleFunctionProps } from '@chakra-ui/react';

function isToast(props: StyleFunctionProps) {
    const { id } = props;
    return id && id.indexOf('toast') !== -1;
}

const style = defineStyle((props) => {
    const { colorScheme } = props;
    let bgColor!: string;
    let iconColor!: string;
    const toast = isToast(props);
    switch (colorScheme) {
        case 'green':
            if (!toast) {
                bgColor = 'green.100';
                iconColor = 'green.700';
            } else {
                bgColor = 'green.700';
                iconColor = 'white';
            }
            break;
        case 'red':
            if (!toast) {
                bgColor = 'danger.50';
                iconColor = 'danger.500';
            } else {
                iconColor = 'danger.50';
                bgColor = 'danger.500';
            }
            break;
        case 'accent':
            {
                bgColor = 'accent.200';
                iconColor = 'black';
            }
            break;
        default:
            break;
    }
    return {
        container: {
            border: 'none',
            bgColor: bgColor,
            borderRadius: 0
        },
        title: {
            fontWeight: toast ? '500' : '600'
        },
        icon: {
            color: iconColor
        }
    };
});

export const Alert: ComponentStyleConfig = {
    baseStyle: style,

    variants: {
        infoBrand: {
            container: {
                bgColor: 'accent.200',
                color: 'black',
                fontWeight: '400'
            },
            title: {
                color: 'black'
            }
        },
        successBrand: {
            container: {
                bgColor: 'accent.200',
                color: 'black',
                fontWeight: '400'
            },
            title: {
                color: 'black'
            },
            icon: {
                color: 'black'
            }
        },
        errorBrand: {
            container: {
                bgColor: 'accent.200',
                color: 'danger.500',
                fontWeight: '400'
            },
            icon: {
                color: 'danger.500'
            }
        }
    }
};
