import { IconProps } from '@chakra-ui/icons';
import { Box, Icon, useToken } from '@chakra-ui/react';
import { useMemo } from 'react';

type UserProgressLevelIconProps = {
    iconProps?: IconProps;
    level?: number;
    userLevel?: number;
};

export const UserLevelHighIcon = (props: UserProgressLevelIconProps) => {
    const [gold, orangeDark, orangeLight, greyLight, greyDark] = useToken('colors', [
        'brand.main',
        '#b57f04',
        '#e6b952',
        'myProgress.grey.light',
        'myProgress.grey.dark'
    ]);

    const { iconProps } = props;
    const level = props.level || 0;

    const userLevel = (props.userLevel ?? props.level) as number;

    const levelActive = level <= userLevel;

    const dots = useMemo(() => {
        const points = [
            'M8.30499 45.41C9.96185 45.41 11.305 44.0669 11.305 42.41C11.305 40.7532 9.96185 39.41 8.30499 39.41C6.64814 39.41 5.30499 40.7532 5.30499 42.41C5.30499 44.0669 6.64814 45.41 8.30499 45.41Z',
            'M10.891 58.418C12.5478 58.418 13.891 57.0748 13.891 55.418C13.891 53.7611 12.5478 52.418 10.891 52.418C9.23414 52.418 7.89099 53.7611 7.89099 55.418C7.89099 57.0748 9.23414 58.418 10.891 58.418Z',
            'M18.26 69.4449C19.9169 69.4449 21.26 68.1018 21.26 66.4449C21.26 64.7881 19.9169 63.4449 18.26 63.4449C16.6032 63.4449 15.26 64.7881 15.26 66.4449C15.26 68.1018 16.6032 69.4449 18.26 69.4449Z',
            'M29.287 76.812C30.9438 76.812 32.287 75.4689 32.287 73.812C32.287 72.1552 30.9438 70.812 29.287 70.812C27.6301 70.812 26.287 72.1552 26.287 73.812C26.287 75.4689 27.6301 76.812 29.287 76.812Z',
            'M42.294 79.4C43.9509 79.4 45.294 78.0569 45.294 76.4C45.294 74.7432 43.9509 73.4 42.294 73.4C40.6372 73.4 39.294 74.7432 39.294 76.4C39.294 78.0569 40.6372 79.4 42.294 79.4Z',
            'M55.301 76.812C56.9579 76.812 58.301 75.4689 58.301 73.812C58.301 72.1552 56.9579 70.812 55.301 70.812C53.6442 70.812 52.301 72.1552 52.301 73.812C52.301 75.4689 53.6442 76.812 55.301 76.812Z',
            'M66.328 69.444C67.9849 69.444 69.328 68.1008 69.328 66.444C69.328 64.7871 67.9849 63.444 66.328 63.444C64.6711 63.444 63.328 64.7871 63.328 66.444C63.328 68.1008 64.6711 69.444 66.328 69.444Z',
            'M73.696 58.417C75.3528 58.417 76.696 57.0738 76.696 55.417C76.696 53.7601 75.3528 52.417 73.696 52.417C72.0391 52.417 70.696 53.7601 70.696 55.417C70.696 57.0738 72.0391 58.417 73.696 58.417Z',
            'M76.283 45.41C77.9399 45.41 79.283 44.0669 79.283 42.41C79.283 40.7532 77.9399 39.41 76.283 39.41C74.6262 39.41 73.283 40.7532 73.283 42.41C73.283 44.0669 74.6262 45.41 76.283 45.41Z'
        ];
        return points.map((item, index) => (
            <Box
                key={index}
                as="path"
                d={item}
                fill={level > index ? (levelActive ? gold : greyDark) : greyLight}
            />
        ));
    }, [greyDark, greyLight, level, levelActive, gold]);
    return (
        <Icon viewBox="0 0 84.611 99.892" h="auto" {...iconProps}>
            <path
                d="M56.57 48.285L37.7866 99.8919L28.8602 82.2149L10.2902 89.8841L29.0736 38.2771L56.57 48.285Z"
                fill={levelActive ? orangeDark : greyLight}
            />
            <path
                d="M28.0377 48.2852L46.8211 99.8922L55.7485 82.2147L74.3184 89.884L55.535 38.277L28.0377 48.2852Z"
                fill={levelActive ? orangeLight : greyLight}
            />
            <path
                d="M42.305 84.61C65.6694 84.61 84.61 65.6694 84.61 42.305C84.61 18.9406 65.6694 0 42.305 0C18.9406 0 0 18.9406 0 42.305C0 65.6694 18.9406 84.61 42.305 84.61Z"
                fill="#ffffff"
            />
            <path
                d="M42.305 69.085C57.3851 69.085 69.61 56.8601 69.61 41.78C69.61 26.6998 57.3851 14.475 42.305 14.475C27.2249 14.475 15 26.6998 15 41.78C15 56.8601 27.2249 69.085 42.305 69.085Z"
                fill={levelActive ? gold : greyLight}
            />

            <svg x="24" y="29" viewBox="0 0 20 20" width="38" height="38">
                <path
                    d="M 15.28125 6.039062 L 19.996094 4.019531 L 17.734375 1.800781 L 14.582031 3.035156 Z M 15.28125 6.039062 "
                    fill="#fff"
                />
                <path
                    d="M 11.347656 15.335938 L 19.882812 5.03125 L 15.308594 7.136719 Z M 11.347656 15.335938 "
                    fill="#fff"
                />
                <path
                    d="M 5.417969 3.035156 L 2.265625 1.800781 L 0.00390625 4.019531 L 4.71875 6.039062 Z M 5.417969 3.035156 "
                    fill="#fff"
                />
                <path
                    d="M 0.117188 5.03125 L 8.652344 15.335938 L 4.691406 7.136719 Z M 0.117188 5.03125 "
                    fill="#fff"
                />
                <path
                    d="M 6.53125 2.945312 L 5.769531 6.214844 L 14.472656 6.214844 L 13.6875 2.945312 Z M 6.53125 2.945312 "
                    fill="#fff"
                />
                <path
                    d="M 5.945312 7.183594 L 10.121094 16 L 14.246094 7.183594 Z M 5.945312 7.183594 "
                    fill="#fff"
                />
                <path
                    d="M 10.121094 1.980469 L 14.035156 1.980469 L 16.785156 1.066406 L 14.050781 0 L 10.121094 0.105469 L 6.191406 0 L 3.453125 1.066406 L 6.207031 1.980469 Z M 10.121094 1.980469 "
                    fill="#fff"
                />
            </svg>

            {...dots}
        </Icon>
    );
};
