import { createToastFn, theme, UseToastOptions } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import { Toast } from '@/components/UI';

export function useToast() {
    const defaultOptions = useMemo(
        () => ({
            isClosable: true,
            duration: 3000,
            containerStyle: {
                transform: 'translateY(-50px)'
            }
        }),
        []
    );

    const toast = useMemo(
        (options?: UseToastOptions) =>
            createToastFn(theme.direction, {
                render(props): React.ReactNode {
                    return Toast(props);
                },
                ...defaultOptions,
                ...options
            }),
        [defaultOptions]
    );

    const errorToast = useCallback(
        (options?: Omit<UseToastOptions, 'status'>) =>
            toast({
                status: 'error',
                variant: 'errorBrand',
                duration: 10000,
                ...options
            }),
        [toast]
    );

    const infoToast = useCallback(
        (options?: Omit<UseToastOptions, 'status'>) =>
            toast({
                status: 'info',
                variant: 'infoBrand',
                ...options
            }),
        [toast]
    );

    const loadingToast = useCallback(
        (options?: Omit<UseToastOptions, 'status'>) =>
            toast({
                status: 'loading',
                ...options
            }),
        [toast]
    );

    const successToast = useCallback(
        (options?: Omit<UseToastOptions, 'status'>) =>
            toast({
                status: 'success',
                variant: 'successBrand',
                ...options
            }),
        [toast]
    );

    const warningToast = useCallback(
        (options?: Omit<UseToastOptions, 'status'>) =>
            toast({
                status: 'warning',
                duration: 5000,
                ...options
            }),
        [toast]
    );

    return {
        errorToast,
        infoToast,
        loadingToast,
        successToast,
        warningToast,
        toast
    };
}
