import { EnvironmentService } from '@/services/EnvironmentService';

const Routes = {
    AboutUs: '/about-us',
    AddToCart: '/adding-to-cart',
    Blog: '/blog',
    Catalog: EnvironmentService.getInstanceCountryCode() === 'PL' ? '/katalog' : '/catalog',
    Certificates: '/certificates',
    ChangePassword: '/change-password',
    Checkout: '/checkout',
    CheckoutSuccess: '/checkout/success',
    ClubRegulations: '/club-regulations',
    Complaints: '/complaints',
    Contact: '/contact',
    EggMembrane: '/egg-membrane',
    Events: '/events',
    Favourites: '/favourites',
    Files: '/files',
    Home: '/',
    Imprint: '/imprint',
    Join: '/join',
    KnowledgeBase: '/knowledge-base',
    LandingPage: '/landing-page',
    MonthActions: '/month-actions',
    MyCareer: '/my-career',
    MyStructure: '/my-structure',
    MyStructureFilter: '/my-structure',
    NewProducts: '/new-products',
    News: '/news',
    NotFound: '/404',
    Notifications: '/notifications',
    Opportunities: '/opportunities',
    OrdersHistory: '/orders',
    PrivacyPolicy: '/privacy-policy',
    Product: '/product',
    Products: '/produkty',
    Profile: '/profile',
    Register: '/register',
    RemindPassword: '/remind-password',
    Sale: '/sale',
    Search: '/search',
    ScientificCouncil: '/scientific-council',
    SignIn: '/login',
    SignOut: '/logout',
    SiteMap: '/site-map',
    SuperOffers: '/super-offers',
    UnavailableProducts: '/products-unavailable'
};

const KnowledgeBaseSubRoutes = {
    KnowledgeBaseSearch: Routes.KnowledgeBase.concat('/search')
};

export const RoutePath = {
    ...Routes,
    ...KnowledgeBaseSubRoutes
};

export default RoutePath;
