import {
    Input,
    InputAddonProps,
    InputGroup,
    InputGroupProps,
    InputLeftAddon,
    InputProps
} from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { AppService } from '@/services/AppService';

interface MemberNumberInputProps {
    formControlRef?: UseFormRegisterReturn<string>;
    inputGroupProps?: InputGroupProps;
    inputAddonProps?: InputAddonProps;
    inputProps?: InputProps;
}

const MemberNumberInput = (props: MemberNumberInputProps) => {
    const { inputProps, inputAddonProps, inputGroupProps, formControlRef } = props;
    const intl = useIntl();
    const instanceCountryCode = AppService.getAppCountryCode();
    return (
        <InputGroup {...inputGroupProps}>
            <InputLeftAddon className="notranslate" {...inputAddonProps}>
                {instanceCountryCode}-
            </InputLeftAddon>
            <Input
                placeholder={intl.formatMessage({ id: 'member-number' })}
                {...formControlRef}
                {...inputProps}
            />
        </InputGroup>
    );
};

export default MemberNumberInput;
